<template>
  <form @submit.prevent="saveSensor" ref="cdcSensorEditForm">
    <div class="modal" tabindex="-1" role="dialog" backdrop="static" id="editCDCSensorModal" @keyup.esc="onEsc($event)">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header header">
            <h3 class="modal-title"><span v-if="action === 'add'">{{ $t('editCDCSensorModal.titleAdd') }}</span><span v-if="action === 'edit'">{{ $t('editCDCSensorModal.titleEdit') }}</span></h3>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form">
              <div v-show="errorMessage" class="alert alert-danger failed">{{ errorMessage }}</div>
              <div class="form-group">
                <label for="deviceApiKey">{{ $t('editCDCSensorModal.deviceApiKey') }}</label>
                <div id="deviceApiKey" class="blue-text" v-if="parentDevice && parentFacility">{{ parentDevice.apikey }} / {{ parentFacility.name }}</div>
              </div>
              <div class="form-group">
                <label for="nameInput">{{ $t('editCDCSensorModal.nameInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.cdcSensor.name.$dirty && $v.cdcSensor.name.$error }" id="nameInput" v-model.trim="cdcSensor.name" :placeholder="$t('editCDCSensorModal.namePlaceholder')" maxlength="255" />
                <div class="invalid-feedback" v-if="$v.cdcSensor.name.$dirty">
                  <div class="error" v-if="!$v.cdcSensor.name.required">{{ $t('editCDCSensorModal.nameRequired') }}</div>
                  <div class="error" v-if="!$v.cdcSensor.name.minLength">{{ $t('editCDCSensorModal.nameMinLength', { min: $v.cdcSensor.name.$params.minLength.min }) }}</div>
                  <div class="error" v-if="!$v.cdcSensor.name.maxLength">{{ $t('editCDCSensorModal.nameMaxLength', { max: $v.cdcSensor.name.$params.maxLength.max }) }}</div>
                </div>
              </div>
               <div class="form-group">
                <label for="statusinfoInput">{{ $t('editCDCSensorModal.statusinfoInput') }}</label>
                <textarea-autosize rows="1" class="form-control" :class="{ 'is-invalid': $v.cdcSensor.statusinfo.$dirty && $v.cdcSensor.statusinfo.$error }" id="statusinfoInput" v-model.trim="cdcSensor.statusinfo" :placeholder="$t('editCDCSensorModal.statusinfoPlaceholder')" maxlength="255" :max-height="150" />
                <div class="invalid-feedback" v-if="$v.cdcSensor.statusinfo.$dirty">
                  <!--<div class="error" v-if="!$v.cdcSensor.statusinfo.url">{{ $t('editCDCSensorModal.statusinfoError') }}</div>-->
                  <div class="error" v-if="!$v.cdcSensor.statusinfo.maxLength">{{ $t('editCDCSensorModal.statusinfoMaxLength', { max: $v.cdcSensor.statusinfo.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="typeInputSelect">{{ $t('editCDCSensorModal.typeInput') }}</label>
                <select class="form-control" id="typeInputSelect" v-model="cdcSensor.type" >
                  <option v-for="cdcSensorType in cdcSensorTypes" :value="cdcSensorType" :key="cdcSensorType">
                    {{ sensorType(cdcSensorType) }}
                  </option>
                </select>
                <div class="invalid-feedback" v-if="$v.cdcSensor.type.$dirty">
                  <div class="error" v-if="!$v.cdcSensor.type.required">{{ $t('editCDCSensorModal.typeRequired') }}</div>
                  <div class="error" v-if="!$v.cdcSensor.type.integer || !$v.cdcSensor.type.between">{{ $t('editCDCSensorModal.typeNumeric') }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="originalidInput">{{ $t('editCDCSensorModal.originalidInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.cdcSensor.originalid.$dirty && $v.cdcSensor.originalid.$error }" id="originalidInput" v-model.trim="cdcSensor.originalid" :placeholder="$t('editCDCSensorModal.originalidPlaceholder')" maxlength="2" />
                <div class="invalid-feedback" v-if="$v.cdcSensor.originalid.$dirty">
                  <div class="error" v-if="!$v.cdcSensor.originalid.required">{{ $t('editCDCSensorModal.originalidRequired') }}</div>
                  <div class="error" v-if="!$v.cdcSensor.originalid.integer || !$v.cdcSensor.originalid.between">{{ $t('editCDCSensorModal.originalidNumeric') }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="pkiInput">{{ $t('editCDCSensorModal.pkiInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.cdcSensor.pki.$dirty && $v.cdcSensor.pki.$error }" id="pkiInput" v-model.trim="cdcSensor.pki" :placeholder="$t('editCDCSensorModal.pkiPlaceholder')" maxlength="255" />
                <div class="invalid-feedback" v-if="$v.cdcSensor.pki.$dirty">
                  <div class="error" v-if="!$v.cdcSensor.pki.maxLength">{{ $t('editCDCSensorModal.pkiMaxLength', { max: $v.cdcSensor.pki.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="connectiontypeSelect">{{ $t('editCDCSensorModal.connectiontypeInput') }}</label>
                <select class="form-control" id="connectiontypeSelect" v-model="cdcSensor.connectiontype" >
                  <option v-for="protocol in cdcSensorProtocols" :value="protocol" :key="protocol">
                    {{ sensorProtocol(protocol) }}
                  </option>
                </select>
                <div class="invalid-feedback" v-if="$v.cdcSensor.connectiontype.$dirty">
                  <div class="error" v-if="!$v.cdcSensor.connectiontype.required">{{ $t('editCDCSensorModal.connectiontypeRequired') }}</div>
                  <div class="error" v-if="!$v.cdcSensor.connectiontype.integer || !$v.cdcSensor.connectiontype.between">{{ $t('editCDCSensorModal.connectiontypeNumeric') }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="connectorSelect">{{ $t('editCDCSensorModal.connectorInput') }}</label>
                <select class="form-control" id="connectorSelect" v-model="cdcSensor.connector" >
                  <option v-for="connector in cdcSensorConnectors" :value="connector" :key="connector">
                    {{ sensorConnector(connector) }}
                  </option>
                </select>
                <div class="invalid-feedback" v-if="$v.cdcSensor.connector.$dirty">
                  <div class="error" v-if="!$v.cdcSensor.connector.required">{{ $t('editCDCSensorModal.connectorRequired') }}</div>
                  <div class="error" v-if="!$v.cdcSensor.connector.integer || !$v.cdcSensor.connector.between">{{ $t('editCDCSensorModal.connectorNumeric') }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="updateintervalInput">{{ $t('editCDCSensorModal.updateintervalInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.cdcSensor.updateinterval.$dirty && $v.cdcSensor.updateinterval.$error }" id="updateintervalInput" v-model.trim="cdcSensor.updateinterval" :placeholder="$t('editCDCSensorModal.updateintervalPlaceholder')" />
                <div class="invalid-feedback" v-if="$v.cdcSensor.updateinterval.$dirty">
                  <div class="error" v-if="!$v.cdcSensor.updateinterval.required">{{ $t('editCDCSensorModal.updateintervalRequired') }}</div>
                  <div class="error" v-if="!$v.cdcSensor.updateinterval.integer || !$v.cdcSensor.updateinterval.minValue">{{ $t('editCDCSensorModal.updateintervalNumeric') }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="factorInput">{{ $t('editCDCSensorModal.factorInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.cdcSensor.factor.$dirty && $v.cdcSensor.factor.$error }" id="factorInput" v-model.trim="cdcSensor.factor" :placeholder="$t('editCDCSensorModal.factorPlaceholder')" />
                <div class="invalid-feedback" v-if="$v.cdcSensor.factor.$dirty">
                  <div class="error" v-if="!$v.cdcSensor.factor.required">{{ $t('editCDCSensorModal.factorRequired') }}</div>
                  <div class="error" v-if="!$v.cdcSensor.factor.integer">{{ $t('editCDCSensorModal.factorNumeric') }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="uuidInput">{{ $t('editCDCSensorModal.uuidInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.cdcSensor.uuid.$dirty && $v.cdcSensor.uuid.$error }" id="uuidInput" v-model.trim="cdcSensor.uuid" :placeholder="$t('editCDCSensorModal.uuidPlaceholder')" maxlength="256" />
                <div class="invalid-feedback" v-if="$v.cdcSensor.uuid.$dirty">
                  <div class="error" v-if="!$v.cdcSensor.uuid.maxLength">{{ $t('editCDCSensorModal.uuidMaxLength', { max: $v.cdcSensor.uuid.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="enabledInput">{{ $t('editCDCSensorModal.enabledInput') }}</label>
                &nbsp;&nbsp;<input type="checkbox" v-model="cdcSensor.enabled">
              </div>
              <div class="form-group">
                <label for="directreadingInput">{{ $t('editCDCSensorModal.directreadingInput') }}</label>
                &nbsp;&nbsp;<input type="checkbox" v-model="cdcSensor.directreading">
              </div>
              <div class="form-group">
                <label for="authmethodInput">{{ $t('editCDCSensorModal.authmethodInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.cdcSensor.authmethod.$dirty && $v.cdcSensor.authmethod.$error }" id="authmethodInput" v-model.trim="cdcSensor.authmethod" :placeholder="$t('editCDCSensorModal.authmethodPlaceholder')" maxlength="2" />
                <div class="invalid-feedback" v-if="$v.cdcSensor.authmethod.$dirty">
                  <div class="error" v-if="!$v.cdcSensor.authmethod.integer || !$v.cdcSensor.authmethod.between">{{ $t('editCDCSensorModal.authmethodNumeric') }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="usernameInput">{{ $t('editCDCSensorModal.usernameInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.cdcSensor.username.$dirty && $v.cdcSensor.username.$error }" id="usernameInput" v-model.trim="cdcSensor.username" :placeholder="$t('editCDCSensorModal.usernamePlaceholder')" maxlength="50" />
                <div class="invalid-feedback" v-if="$v.cdcSensor.username.$dirty">
                  <div class="error" v-if="!$v.cdcSensor.username.maxLength">{{ $t('editCDCSensorModal.usernameMaxLength', { max: $v.cdcSensor.username.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="passwordInput">{{ $t('editCDCSensorModal.passwordInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.cdcSensor.password.$dirty && $v.cdcSensor.password.$error }" id="passwordInput" v-model.trim="cdcSensor.password" :placeholder="$t('editCDCSensorModal.passwordPlaceholder')" maxlength="100" />
                <div class="invalid-feedback" v-if="$v.cdcSensor.password.$dirty">
                  <div class="error" v-if="!$v.cdcSensor.password.maxLength">{{ $t('editCDCSensorModal.passwordMaxLength', { max: $v.cdcSensor.password.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="mainuriInput">{{ $t('editCDCSensorModal.mainuriInput') }}</label>
                <textarea-autosize rows="1" class="form-control" :class="{ 'is-invalid': $v.cdcSensor.mainuri.$dirty && $v.cdcSensor.mainuri.$error }" id="mainuriInput" v-model.trim="cdcSensor.mainuri" :placeholder="$t('editCDCSensorModal.mainuriPlaceholder')" maxlength="255" :max-height="150" />
                <div class="invalid-feedback" v-if="$v.cdcSensor.mainuri.$dirty">
                  <div class="error" v-if="!$v.cdcSensor.mainuri.url">{{ $t('editCDCSensorModal.mainuriUrlError') }}</div>
                  <div class="error" v-if="!$v.cdcSensor.mainuri.maxLength">{{ $t('editCDCSensorModal.mainuriMaxLength', { max: $v.cdcSensor.mainuri.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="loginuriInput">{{ $t('editCDCSensorModal.loginuriInput') }}</label>
                <textarea-autosize rows="1" class="form-control" :class="{ 'is-invalid': $v.cdcSensor.loginuri.$dirty && $v.cdcSensor.loginuri.$error }" id="loginuriInput" v-model.trim="cdcSensor.loginuri" :placeholder="$t('editCDCSensorModal.loginuriPlaceholder')" maxlength="255" :max-height="150" />
                <div class="invalid-feedback" v-if="$v.cdcSensor.loginuri.$dirty">
                  <div class="error" v-if="!$v.cdcSensor.loginuri.url">{{ $t('editCDCSensorModal.loginuriUrlError') }}</div>
                  <div class="error" v-if="!$v.cdcSensor.loginuri.maxLength">{{ $t('editCDCSensorModal.loginuriMaxLength', { max: $v.cdcSensor.loginuri.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="valueuriInput">{{ $t('editCDCSensorModal.valueuriInput') }}</label>
                <textarea-autosize rows="1" class="form-control" :class="{ 'is-invalid': $v.cdcSensor.valueuri.$dirty && $v.cdcSensor.valueuri.$error }" id="valueuriInput" v-model.trim="cdcSensor.valueuri" :placeholder="$t('editCDCSensorModal.valueuriPlaceholder')" maxlength="255" :max-height="150" />
                <div class="invalid-feedback" v-if="$v.cdcSensor.valueuri.$dirty">
                  <div class="error" v-if="!$v.cdcSensor.valueuri.url">{{ $t('editCDCSensorModal.valueuriUrlError') }}</div>
                  <div class="error" v-if="!$v.cdcSensor.valueuri.maxLength">{{ $t('editCDCSensorModal.valueuriMaxLength', { max: $v.cdcSensor.valueuri.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="jsonInput">{{ $t('editCDCSensorModal.jsonInput') }}</label>
                <textarea-autosize rows="1" class="form-control" :class="{ 'is-invalid': $v.cdcSensor.json.$dirty && $v.cdcSensor.json.$error }" id="jsonInput" v-model.trim="cdcSensor.json" :placeholder="$t('editCDCSensorModal.jsonPlaceholder')" maxlength="1024" :max-height="300" />
                <div class="invalid-feedback" v-if="$v.cdcSensor.json.$dirty">
                  <div class="error" v-if="!$v.cdcSensor.json.url">{{ $t('editCDCSensorModal.jsonUrlError') }}</div>
                  <div class="error" v-if="!$v.cdcSensor.json.maxLength">{{ $t('editCDCSensorModal.jsonMaxLength', { max: $v.cdcSensor.json.$params.maxLength.max }) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary"><span v-if="action === 'add'">{{ $t('editCDCSensorModal.buttonAdd') }}</span><span v-if="action === 'edit'">{{ $t('editCDCSensorModal.buttonSave') }}</span></button>
            <button type="button" class="btn btn-secondary" @click="close">{{ $t('editCDCSensorModal.close') }}</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from 'jquery'
import { required, integer, between, minValue, minLength, maxLength } from 'vuelidate/lib/validators'
import cdcsensorService from '@/services/cdcsensors'
import ModalViewMixin from '@/mixins/ModalViewMixin.js'

export default {
  name: 'EditCDCSensorModal',
  mixins: [ModalViewMixin],
  props: {
    parentFacility: {
      type: Object,
      required: true
    },
    parentDevice: {
      type: Object,
      required: true
    },
    selectedCDCSensor: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      cdcSensor: {},
      errorMessage: '',
      cdcSensorTypes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      cdcSensorProtocols: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      cdcSensorConnectors: [0, 1, 2, 3, 4, 5]
    }
  },
  watch: {
    modalOpened () {
      this.copyCDCSensor()
      this.triggerFocus()
    }
  },
  validations: {
    cdcSensor: {
      name: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(255)
      },
      type: {
        required,
        integer,
        between: between(0, 10)
      },
      originalid: {
        required,
        integer,
        between: between(1, 1000000)
      },
      pki: {
        maxLength: maxLength(1000)
      },
      connectiontype: {
        required,
        integer,
        between: between(0, 10)
      },
      connector: {
        required,
        integer,
        between: between(0, 10)
      },
      updateinterval: {
        required,
        integer,
        minValue: minValue(0)
      },
      factor: {
        required,
        integer
      },
      enabled: {
        required
      },
      directreading: {
        required
      },
      authmethod: {
        integer,
        between: between(0, 10)
      },
      username: {
        maxLength: maxLength(50)
      },
      password: {
        maxLength: maxLength(100)
      },
      uuid: {
        maxLength: maxLength(256)
      },
      mainuri: {
        // url,
        maxLength: maxLength(255)
      },
      statusinfo: {
        // url,
        maxLength: maxLength(255)
      },
      loginuri: {
        // url,
        maxLength: maxLength(255)
      },
      valueuri: {
        // url,
        maxLength: maxLength(255)
      },
      json: {
        maxLength: maxLength(1024)
      }
    }
  },
  mounted () {
    $('#editCDCSensorModal').on('shown.bs.modal', () => {
      this.triggerFocus()
    })
  },
  methods: {
    copyCDCSensor () {
      this.cdcSensor = Object.assign({}, this.selectedCDCSensor)
    },
    saveSensor () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      if (this.action === 'add') {
        cdcsensorService.addCDCSensor(this.cdcSensor).then((createdCDCSensor) => {
          this.flagChanges()
        }).catch(error => {
          this.errorMessage = this.localizedBackendMessage(error.message)
        })
      } else if (this.action === 'edit') {
        cdcsensorService.updateCDCSensor(this.cdcSensor.id, this.cdcSensor).then((updatedCDCSensor) => {
          this.flagChanges()
        }).catch(error => {
          this.errorMessage = this.localizedBackendMessage(error.message)
        })
      }
    },
    close () {
      this.$v.$reset()
      this.errorMessage = ''
      // zurück zum ursprünglichen 'selected cdcSensor',
      // um etwaigen Änderungen im Formular loszuwerden
      this.copyCDCSensor()
      //
      // this.$refs.cdcSensorEditForm.reset()
      this.emitClosed()
      $('#editCDCSensorModal').modal('hide')
    },
    triggerFocus () {
      // Alle 'textarea-autosize' berühren!
      $('#mainuriInput').trigger('focus')
      $('#statusinfoInput').trigger('focus')
      $('#loginuriInput').trigger('focus')
      $('#valueuriInput').trigger('focus')
      $('#nameInput').trigger('focus')
    },
    sensorType (type) {
      return this.attributeLabelForCode('sensorType', type)
    },
    sensorProtocol (protocol) {
      return this.attributeLabelForCode('sensorProtocol', protocol)
    },
    sensorConnector (connector) {
      return this.attributeLabelForCode('sensorConnector', connector)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-dialog {
    width: 400px;
  }

  .blue-text {
    color: #0069d9;
    font-weight: 700;
  }

  .td-right {
    text-align: right;
  }
}
</style>
