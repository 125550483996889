import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Vuelidate from 'vuelidate'
import { library as faLibrary } from '@fortawesome/fontawesome-svg-core'
import { faHome, faSearch, faPlus, faEdit, faTrash, faCheck, faCompressAlt, faExpandAlt, faEraser, faClock, faArrowAltCircleLeft, faUndo, faAlignLeft, faAlignCenter, faEye, faPaperclip, faClone, faServer, faBuilding, faHistory /* , faToggleOn, faToggleOff */ } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { i18n } from './i18n'

import TextareaAutosize from 'vue-textarea-autosize'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import VTitle from 'v-title'
import 'v-title/lib/element-ui'
// import VueCurrencyInput from 'vue-currency-input'
// import LiquorTree from 'liquor-tree'
import VueChartkick from 'vue-chartkick'
import Chart from 'chart.js'

// Bootstrap axios
axios.defaults.baseURL = '/api'
axios.defaults.headers.common.Accept = 'application/json'
axios.interceptors.response.use(
  response => response,
  (error) => {
    if (error.status === 401 && error.config && !error.config.__isRetryRequest) {
      this.$store.dispatch('logout')
      this.$router.push({ name: 'login' })
    }
    return Promise.reject(error)
  }
)

// Enable Vuelidate
Vue.use(Vuelidate)

// Enable TextareaAutosize
Vue.use(TextareaAutosize)

// Enable VTitle
Vue.use(VTitle)

// Enable VueCurrencyInput
// Vue.use(VueCurrencyInput)

// Enable LiquorTree
// Vue.use(LiquorTree)

Vue.use(VueChartkick.use(Chart))

// Set up FontAwesome
faLibrary.add(faHome, faSearch, faPlus, faEdit, faTrash, faCheck, faCompressAlt, faExpandAlt, faEraser, faClock, faArrowAltCircleLeft, faUndo, faAlignLeft, faAlignCenter, faEye, faPaperclip, faClone, faServer, faBuilding, faHistory /* , faToggleOn, faToggleOff */)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component('vue-ctk-date-time-picker', VueCtkDateTimePicker)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
